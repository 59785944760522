import * as Sentry from '@sentry/nextjs';
import { GetStaticPropsContext } from 'next';
import { useTranslations } from 'next-intl';
import { useUser } from '@clerk/nextjs';

import { ErrorContainer } from '@shared/ui';
import { PageLayout } from '@tbgpt-web/ui';

export const Error404 = () => {
  const t = useTranslations('Error404');

  const meta = {
    title: t('meta.title'),
  };

  // Set Sentry additional context
  const { isLoaded, isSignedIn, user } = useUser();
  if (isLoaded && isSignedIn) {
    Sentry.setUser({
      id: user.publicMetadata.uuid as string,
      planId: user.publicMetadata.planId,
    });
  }
  Sentry.setTag('op.page', '404');
  Sentry.setTag('op.pageType', 'front');

  return (
    <PageLayout meta={meta} title={t.rich('title')}>
      <ErrorContainer
        content={t.rich('content')}
        title={t.rich('contentTitle')}
      />
    </PageLayout>
  );
};

export default Error404;

export const getStaticProps = async (context: GetStaticPropsContext) => {
  return {
    props: {
      messages: (await import(`../../../messages/${context.locale}.json`))
        .default,
    },
  };
};
